// Gatsby supports TypeScript natively!
import React,{ useEffect,Component } from "react"
import Modal from "react-modal"
import { PageProps } from "gatsby"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../../static/css/dataTables.bootstrap.css"
import "../../static/css/datatable-ajustes.css"
Modal.setAppElement("#___gatsby")
const jQuery = require("jquery")
const axios = require("axios")
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    // marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background:'#313444'
  }
};
const PublicacionesLists2 = () => {
  
      useEffect(() => {
        window.slider();
        window.publicaciones();
    }, [])
  
  
return (
  <Layout title="Publicaciones" show_bread={true} >
    <SEO title="Publicaciones" />
    <div className="section mcb-section equal-height publicaciones" style={{paddingTop:'100px', paddingBottom:'60px' }} id="mainp">
        <div className={"section_wrapper mcb-section-inner"}>
            <div className={"wrap mcb-wrap one  valign-top clearfix"}>
                 <div className={"mcb-wrap-inner"}>
                 <div className={"text-center"}>
                 <h3 style={{width:"100%"}}> Análisis macroeconómico</h3>
     
                 </div>
                    <table id="example" className={"table table-striped table-bordered"} style={{width:'100%'}}>
                        <thead>
                        <tr>
                            <th className={"col-sm-3"}>Fecha de publicación</th>
                            <th className={"col-sm-7"}>Documento</th>
                            <th className={"col-sm-2"}>Archivo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                              <td>2020-10-05</td>
                              <td>Addendum ESG - BAFAR </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BAFAR-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-05</td>
                              <td>Addendum ESG - FNOVA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FNOVA-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-02</td>
                              <td>LAMOSA: Anuncio del cierre de la adquisición de Eurocerámica S.A. en Colombia </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/LAMOSA-02-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-02</td>
                              <td>HOTEL: Approval of all proposals during Ordinary General Shareholders' Meeting. HOTEL will carry out capitalization and the cancellation of shares deposited in the Treasury </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-02-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-01</td>
                              <td>JAVER: HR Ratings revisó a la baja la calificación de largo plazo y mantiene Perspectiva Estable; y ratificó la calificación de corto plazo </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-01-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-01</td>
                              <td>Addendum ESG - FMTY </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-29</td>
                              <td>FMTY: Anuncia asuntos a tratar en su Asamblea Ordinaria </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-29-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-28</td>
                              <td>Addendum ESG - UPSITE </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/UPSITE-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-28</td>
                              <td>Addendum ESG - JAVER </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - LAMOSA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/LAMOSA-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - TMM </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/TMM-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - VITRO </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/VITRO-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - GISSA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GISSA-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-15</td>
                              <td>HOTEL: Will seek to strengthen its capital structure through a subscription of shares for its current shareholders</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-15-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-14</td>
                              <td>Addendum ESG - QUÁLITAS </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/Q-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-14</td>
                              <td>Addendum ESG - HOTEL </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-03</td>
                              <td>Inicio de Cobertura - LAMOSA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/LAMOSA-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-08-19</td>
                              <td>JAVER: Acuerda <em>waiver</em> con acreedores</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-19-08-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-08-14</td>
                              <td>Inicio de Cobertura - TMM </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/TMM-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-08-10</td>
                              <td>BWMX: Extraordinarios resultados y revisión al alza en su Guía Anual, mejoran nuestras expectativas y sustentan nuestra recomendación de inversión de Compra, con un P.O. de US$21.17 (+45.9%)</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BWMX-Analisis-2T20-3.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-08-03</td>
                              <td>BAFAR: Resultados defensivos ante COVID, incrementa gap en valuación por proyecciones más sólidas</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BAFAR-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-08-03</td>
                              <td>BWMX: 2Q20 Conference Call</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BWMX-Conference-Call-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-08-03</td>
                              <td>HOTEL: Ends marketing and co-branding agreement</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-03-08-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-31</td>
                              <td>UPSITE: Concludes first subscription round and the second-round initiates; and capitalizes liabilities with Certificates held in Treasury</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/UPSITE-31-07-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-31</td>
                              <td>BWMX: Outstanding results. Remains as our Mexican top pick retailer</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BWMX-Analisis-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-30</td>
                              <td>GISSA: Resiente afectación por COVID en sector automotriz global, atractiva oportunidad de entrada por valuación</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GISSA-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-28</td>
                              <td>UPSITE: Maintain a steady growth of invested capital, expands operating efficiency, and continues with its investment plan</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/UPSITE-Analisis-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-28</td>
                              <td>FNOVA: Supera a nuestra expectativa de crecimiento</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FNOVA-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-24</td>
                              <td>HOTEL: Sanitary measures caused low occupancy, remarkable outstanding cost control. HOTEL is still our favorite in the sector</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-Analisis-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-24</td>
                              <td>CADU: Refuerza posición financiera y operativa, con el objeto de acelerar recuperación</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-24</td>
                              <td>FMTY: Ligeramente mayor a nuestros estimados. Destaca flexibilidad del balance, persiste un importante descuento en el CBFI.</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-Primer-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-23</td>
                              <td>CYDSA: Slightly lower than our estimates. Strengthens its financial position and defensive attributes</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CYDSA-Analisis-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-22</td>
                              <td>QUÁLITAS: Supera nuestros estimados, continúa mostrando tracción comercial. Incrementamos Precio Objetivo a 12 meses</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/Q-Analisis-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-20</td>
                              <td>JAVER: Muestra afectación por COVID-19 pero logra resultados por encima de lo esperado. La recomendación es MANTENER</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Primer-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-20</td>
                              <td>CYDSA: We expect a stable performance</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CYDSA-Primer-Comentario-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-14</td>
                              <td>UPSITE: Coverage Initiated</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/UPSITE-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-14</td>
                              <td>VITRO: Negativo. Baja demanda por COVID impacta resultados del primer semestre; se profundizan esfuerzos de optimización </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/VITRO-Analisis-2T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-06</td>
                              <td>GFAMSA: Calificadoras S&P y Fitch revisan calificaciones a la baja</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GFAMSA-06-07-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-07-01</td>
                              <td>GFAMSA: Revocan concesión a Banco Ahorro Famsa</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GFAMSA-01-07-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-29</td>
                              <td>GFAMSA: Presenta solicitud bajo el capítulo 11 para facilitar reestructura</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GFAMSA-29-06-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-29</td>
                              <td>CADU: Comentario al Reporte Trimestral </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Analisis-1T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-24</td>
                              <td>Credito Real: Ensures Suitable Levels of Liquidity with the Renewal of Credit Lines and the Launching of a New Debt Securities Program</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CREAL-24-06-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-18</td>
                              <td>Situación Actual de la Valuación Independiente en México – CKDs y CERPIs </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/Apalache-situación actual-CKDs-CERPIs.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-17</td>
                              <td>CADU: Desaceleración económica y Covid-19 impactan resultados del trimestre. No obstante, persiste valuación atractiva.</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Primer-Comentario-1T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-17</td>
                              <td>QUÁLITAS: Incluído en índice</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/Q-17-06-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-16</td>
                              <td>Inicio de Cobertura - VITRO </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/VITRO-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-06-08</td>
                              <td>QUÁLITAS: Coverage Initiated </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/QUALITAS-Inicio-Cobertura-en.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-06-08</td>
                              <td>Inicio de Cobertura - QUÁLITAS</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/QUALITAS-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-05-28</td>
                              <td>BWMX: Anuncia pago de dividendos</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BWMX-28-05-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-05-27</td>
                              <td>CADU: Aclaración nota periodística</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-27-05-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-05-26</td>
                              <td>BWMX: Un Best-In-Class del Sector Retail. Resultados Positivos. Marzo muestra cierta afectación por COVID-19.</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BWMX-Primer-Comentario-1T20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-05-15</td>

                              <td>Inicio de Cobertura - BAFAR</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BAFAR-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-04-03</td>

                              <td>COVID-19 e Impacto en Sector Bienes Raíces en México 2020</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/COVID-03-04-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-04-02</td>

                              <td>NOTICIA NEUTRAL/POSITIVA: FMTY Anuncia que suspenderá el proceso de adquisición de oficinas en Guadalajara “La Perla”</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-02-04-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td>2020-03-27</td>

                              <td>Inicio de Cobertura - BVL</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BVL-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-03-09</td>

                              <td>GISSA: Draxton se encuentra sólido para un 2020 retador. Esperamos que continúe la recuperación de Vitromex y Cinsa</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GISSA-Analisis-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-03-02</td>

                              <td>HOTEL: Solidez operativa para potenciar resultados. Nuestra favorita del sector</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-Analisis-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-27</td>

                              <td>GFAMSA: Cerró el 2019 con un desempeño positivo. Esperamos un buen año apoyado en mejorías en su estructura financiera y mayores eficiencias operativas. Precio muy castigado</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FAMSA-Analisis-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-26</td>

                              <td>CYDSA: Neutral. Eficiencia y expansión de márgenes a pesar de volatilidad en commodities</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CYDSA-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-25</td>

                              <td>JAVER: Este año se desenvolverá en un contexto con mayor certidumbre</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Analisis-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-25</td>

                              <td>CADU: Reporte positivo superando previsiones, principalmente en rentabilidad. Sin duda, sigue siendo la mejor alternativa de inversión del sector</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-24</td>

                              <td>GFAMSA: Resultados positivos apoyados en avances y mejorías en su estructura operativa y financiera. Se anticipa un año favorable</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FAMSA-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-24</td>

                              <td>CADU: Inmejorable punto de entrada. Top pick del sector Vivienda</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Perspectivas-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-21</td>

                              <td>GISSA: Neutral. Apuntala rentabilidad y posicionamiento comercial a pesar del estado de sus sectores</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GIS-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-21</td>

                              <td>HOTEL: Reaches its Guidance; shows recovery and positive expectations towards 2020</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-19</td>

                              <td>FMTY: Positivo. Muy en línea con nuestras previsiones y cumpliendo con su guía. Se anticipa un 2020 favorable para sus inversionistas</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-18</td>

                              <td>JAVER: Presentó resultados superiores a nuestras previsiones; su Guía para 2020 refleja una mayor certidumbre que hace un año</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-11</td>

                              <td>Inicio de Cobertura - GISSA</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GISSA-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-07</td>

                              <td>NOTICIA POSITIVA: FMTY Anuncia adquisición de oficinas en Guadalajara </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-07-02-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-02-07</td>

                              <td>FNOVA: Perspectivas positivas respecto a rentabilidad y crecimiento ordenado</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FNOVA-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-01-30</td>

                              <td>FNOVA: Estabilidad en crecimiento, continuará dinamismo por incorporación de activos industriales en plazas clave</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FNOVA-Primer-Comentario-4T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-01-13</td>

                              <td>Inicio de Cobertura - FNOVA</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FNOVA-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2020-01-08</td>

                              <td>Inicio de Cobertura - FMTY</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-12-05</td>

                              <td> CYDSA: Calificaciones de S&P y Fitch respecto a la reapertura de sus Senior Notes</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CYDSA-05-12-19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>

                          <tr>
                              <td> 2019-12-04</td>

                              <td> CYDSA anuncia la reapertura de sus Senior Notes</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CYDSA-04-12-19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-12-03</td>

                              <td> JAVER: Refinanciamiento de deuda y Plan Nacional de Vivienda mejoran ligeramente sus perspectivas a mediano plazo</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Analisis-3T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-12-02</td>

                              <td> Inicio de Cobertura - HOTEL</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-11-14</td>

                              <td> JAVER liquida sus Notas Senior 9.875%</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-14-11-19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-11-06</td>

                              <td> GFAMSA: Comentario al Reporte Trimestral</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FAMSA-Analisis-3T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-11-01</td>

                              <td> CADU: Comentario al Reporte Trimestral</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Analisis-3T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-11-01</td>

                              <td> Inicio de Cobertura - CYDSA</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CYDSA-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-10-25</td>

                                <td> GFAMSA: Resultados operativos en línea con lo esperado, sin embargo, el refinanciamiento de su deuda sigue generando dudas</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FAMSA-Comentario-3T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-10-24</td>

                                <td> CADU: Resultado alineado a previsiones, se mantienen las metas que se han fijado para este año. Atractiva valuación</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Comentario-3T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-10-22</td>

                                <td> JAVER: Inferior a nuestras previsiones, sin embargo, hay señales de moderación en las caídas</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Comentario-3T19.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-10-01</td>

                              <td> Inicio de Cobertura - BETTERWARE</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BETTERWARE-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-09-02</td>

                                <td> Inicio de Cobertura - JAVER</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                              <td> 2019-08-15</td>

                              <td> Inicio de Cobertura - GFAMSA</td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GFAMSA-Inicio-Cobertura.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                            <td> 2019-08-05</td>

                            <td> DD3 Acquisition Corp. announces it has entered into a definitive agreement to merge with Betterware</td>
                            <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/DD3-05-08-19.pdf">
                                <i className={"fa fa-download"}></i> Descargar
                            </a>
                            </td>
                        </tr>
                        <tr>
                            <td> 2019-06-04</td>

                            <td> CADU ajustó cifras del 4T18 y 1T19. En consecuencia, revisamos cifras proyectadas y elevamos ligeramente nuestro PO.</td>
                            <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-04-06-19.pdf">
                                <i className={"fa fa-download"}></i> Descargar
                            </a>
                            </td>
                        </tr>
                        <tr>
                            <td> 2019-05-10</td>

                            <td> Inicio de Cobertura - CADU</td>
                            <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Inicio-Cobertura.pdf">
                                <i className={"fa fa-download"}></i> Descargar
                            </a>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
               
            </div>
        </div>
    </div>
   
  </Layout>
)
}


export default PublicacionesLists2
